<template>
    <layout>
        <template slot="content">
            <router-view />
        </template>
    </layout>
</template>

<script>
import Layout from '@/views/layouts/Layout';

export default {
    name: 'NotificationsModule',

    components: {
        Layout
    }
};
</script>

<style lang="scss">

</style>
